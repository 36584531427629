$(document).on("click", ".header-nav-menu-item a", function() {
  const link = $(this)
    .attr("href")
    .replace("#", "");

  dataLayer.push({
    event: "GAevent",
    eventCategory: "legion-ageless-shooters-landing",
    eventAction: "Button-click",
    eventLabel: "Go-to-" + link,
  });
});

$(document).on("click", ".landing-slide-model-buy-link", function() {
  const model = $(this).data("model");

  dataLayer.push({
    event: "GAevent",
    eventCategory: "legion-ageless-shooters-landing",
    eventAction: "Button-click",
    eventLabel: "Go-buy-model-" + model,
  });
});

$(document).on("click", ".landing-slide-05 .contacts a", function() {
  const social = $(this).data("social");

  dataLayer.push({
    event: "GAevent",
    eventCategory: "legion-ageless-shooters-landing",
    eventAction: "Open-section",
    eventLabel: "Learn-more-contact-" + social,
  });
});

$(document).on("click", ".landing-slide-05 .partners a", function() {
  dataLayer.push({
    event: "GAevent",
    eventCategory: "legion-ageless-shooters-landing",
    eventAction: "Button-click",
    eventLabel: "Learn-more-gameinside",
  });
});
